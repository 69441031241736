@import "_default.scss_include-mixins";

.popup--diensten {
	
	width:1000px;

	@include sm {
		width:260px;
	}

	.content {
		float:left;
		width:100%;
		padding-top:0;
		padding-bottom:0;
		padding:80px;
		padding-right:110px;
		background-color:#fff;

		@include sm {
			padding:15px;
		}

		h1 {
			margin:0;
			padding:0;
			font-weight:bold;
			font-size:70px;
			margin-bottom:60px;

			@include sm {
				font-size:20px;
				margin-bottom:20px;
				line-height:30px;
			}

		}

		p {
			margin-left:30px;
			width:100%;

			@include sm {
				margin-left:0;
			}

		}
	}

}