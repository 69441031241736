@import "_default.scss_include-mixins";

.textblock-1 {

	float:left;
	width:100%;

	p,ul li,.p {
		padding:0;
		margin:0;
		font-size:18px;
		line-height:35px;
		color:#000;

		a {
			color:#888;
		}

		@include sm {
			font-size:14px;
			line-height:24px;
		}

	}

	ul {
		list-style-type: disc;
	}
	
	h1, h2 {
	    font-family:'Gilroy',sans-serif;
	    font-weight:normal;
	    font-size:30px;
	    line-height:30px;
	    margin:0;
	    padding:0;
	    color:#000;

	    @include sm {
			font-size:40px;
			line-height:40px;
		}

	}

}

.textblock-2 {

	float:left;
	width:100%;

	p,ul li,.p {
		padding:0;
		margin:0;
		font-size:18px;
		line-height:35px;
		color:#fff;

		a {
			color:#888;
			text-decoration:none;
		}

		@include sm {
			font-size:14px;
			line-height:24px;
		}

	}

	ul {
		list-style-type: disc;
	}
		
	h2 {
	    font-family:'Gilroy',sans-serif;
	    font-weight:bold;
	    font-size:100px;
	    line-height:100px;
	    margin:0;
	    margin-bottom:20px;
	    padding:0;
	    color:#fff;

	    @include sm {
			font-size:40px;
			line-height:40px;
		}

	}

}