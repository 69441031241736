@import "_default.scss_include-mixins";

body {
	font-family:'Open Sans', serif;
	background-color:#fffdfc;
}

/*
Selection
*/
::selection {
	background:#000;
	color:#fff;
}