@import "_default.scss_include-mixins";

.page.content--home {

	.spotlight {

		float:left;
		width:100%;
		height:100vh;
		min-height:650px;

		@include sm {
			min-height:auto;
		}

		.inside {

			position:absolute;
			width:1240px;
			height:650px;
			top:50%;
			left:50%;
			transform:translate(-50%,-50%);

			@include xl {
				width:800px;
				height:430px;
			}

			@include sm {
				width:100%;
				height:400px;
			}

			.left { 
				float:left;
				width:480px; 

				@include xl {
					width:280px;
				}

				@include sm {
					width:100%;
					text-align:center;					
				}

				.image {
					float:left;
					width:100%;
					height:650px;
					margin-top:25px;
					background-position:center;
					background-repeat:no-repeat;
					background-size:cover;

					@include xl2 {
						height:600px;
					}

					@include xl {
						height:430px;
						margin-top:0;
					}

					@include sm {
						float:none;
						display:inline-block;
						margin-top:40px;
						height:240px;
						width:180px;
					}

				}

			}

			.right {
				float:left;
				margin-left:60px;
				width:700px;

				@include xl {
					width:420px;
				}

				@include sm {
					margin:0;
					width:100%;
					text-align:center;
				}

				h1 {
					float:left;
					font-family:'Gilroy',arial;
					font-weight:bold;
					font-size:80px;
					line-height:80px;
					color:#F23700;
					margin:0;
					padding:0;
					margin-top:150px;

					@include xl {
						font-size:50px;
						margin-top:90px;
					}

					@include sm {
						float:none;
						font-size:30px;
						margin-top:20px;
						line-height:30px;
					}

				}

				h2 {
					float:left;
					font-family:'Gilroy',arial;
					font-weight:normal;
					font-size:70px;
					line-height:70px;
					color:#5E5E5E;
					margin:0;
					padding:0;
					margin-top:40px;

					@include xl {
						font-size:40px;
						line-height:40px;
						margin-top:20px;
					}

					@include sm {
						float:none;
						font-size:20px;
						line-height:20px;
						margin-top:10px;
					}

				}

				nav {

					float:left;
					width:100%;
					margin-top:40px;

					@include sm {
						display:none;
						margin-top:10px;
					}

					a {
						float:left;
						margin-right:25px;
						font-size:28px;
						color:#3B3B3B;
						font-family:'Gilroy',arial;
						opacity:0.5;
						text-decoration: none;
						transition: all 0.2s;

						@include xl {
							font-size:21px;
						}

						@include sm {
							margin-bottom:5px;
							width:100%;
							text-align:center;
						}

						&:hover {
							opacity:1;
							color:#f23700;
						}
					}

				}
			}

		}

	}

	.rest {

		float:left;
		width:100%;
		min-height:1000px;

		/*
		Reviews *********************************
		Reviews *********************************
		Reviews *********************************
		Reviews *********************************
		*/
		.reviews {

			float:left;
			width:100%;
			margin-top:0;

			@include xl2 {
				margin-top:120px;
			}

			@include sm {
				margin-top:40px;
			}

			.cases {
				float:left;
				width:100%;
				position:relative;

				.nav {
					position:absolute;
					width:60px;
					height:60px;
					border-radius:50%;
					border:2px solid #ccc;
					cursor:pointer;
					box-shadow:0 0 10px rgba(0,0,0,0.05);

					@include sm {
						display:none;
					}

					&:hover {
						span {
							opacity:0.4;
						}
					}

					&.prev {
						left:50%;
						margin-left:-500px;
						top:50%;
						margin-top:-20px;
						transform:rotate(180deg);
					}

					&.next {
						right:50%;
						margin-right:-500px;
						top:50%;
						margin-top:-20px;
					}

					span {
						position:absolute;
						top:50%;
						left:50%;
						transform:translate(-50%,-50%);
						width:35px;
						height:35px;
						opacity:0.2;
						transition: all 0.2s;
					}

				}

				.swiper-container {
				  width:800px;

					@include sm {
						width:280px;
					}

				  h2 {
				  	text-align:center;
				  	font-size:30px;
				  	margin-bottom:20px;
				  }

				  p {
				  	opacity:0.7;
				  	text-align:center;
				  }

				  .swiper-slide {
				  	padding:40px;
				  	background-color:#F5F5F5;
				  }

				}

			}

			.logos {
				clear:both;
				float:left;
				margin-top:120px;
				width:100%;
				height:200px;

				@include sm {
					margin-top:40px;
					height:104px;
				}

				.item {
					float:left;
					width:200px;
					height:200px;
					margin-left:20px;
					margin-right:20px;
					border:2px dashed rgba(0,0,0,0.1);
					border-radius:50%;
					background-size:contain;
					background-position:center;
					background-size:80%;
					background-repeat:no-repeat;
					background-color:#fff;

					@include sm {
						width:100px;
						height:100px;
					}

				}

			}

		}

	}

	/*
	USPS *********************************
	USPS *********************************
	USPS *********************************
	USPS *********************************
	*/
	.usps {

		float:left;
		width:100%;
		margin-top:120px;

		@include sm {
			margin-top:40px;
		}

		.textblock-1 {

			margin-bottom:80px;

			@include sm {
				margin-bottom:30px;
			}

			h2 {
				font-size:60px;
				font-weight:normal;

				@include sm {
					font-size:30px;
				}
				
				span {
					color:#f23700;
					font-weight:bold;
				}

			}

		}
		.items {
			float:left;
			width:100%;
			text-align:center;

			.item {
				display:inline-block;
				width:700px;
				font-size:30px;
				line-height:40px;
				margin-bottom:40px;
				color:#000;
				opacity:0.5;

				@include sm {
					width:280px;
					font-size:16px;
					line-height:20px;
					margin-bottom:20px;
				}

			}
		}

	}

	/*
	Aanpak *********************************
	Aanpak *********************************
	Aanpak *********************************
	Aanpak *********************************
	*/
	.aanpak {

		float:left;
		width:100%;
		height:677px;
		position:relative;
		margin-top:120px;
		overflow:hidden;

		@include xl {
			height:977px;
		}

		@include sm {
			position:relative;
			overflow:inherit;
			text-align:center;
			height:auto;

			.mobile {
				float:left;
				width:100%;
			}
		}

		.image {
			position:absolute;
			top:-80px;
			right:-280px;
			width:450px;
			height:550px;
			background-image:url('../img/static/aanpak.jpg');
			background-repeat:no-repeat;
			background-size:cover;

			@include sm {
				position:inherit;
				float:left;
				margin-top:20px;
				width:100%;
				height:300px;
			}

		}

		.left {
			position:absolute;
			left:0;
			top:80px;
			width:80%;
			padding:40px;
			background-color:#0F93CC;

			@include sm {
				position:inherit;
				float:left;
				width:100%;
				padding:20px;
				padding-top:40px;
				padding-bottom:40px;
				top:0;
			}

			.inside {
				float:right;
				margin-right:200px;
				width:900px;

				@include xl {
					width:560px;
				}

				@include sm {
					width:100%;
					margin-right:0;
				}

			}

			.padding-left {
				margin-left:80px;

				@include sm {
					margin-left:0;
				}

				.button {
					margin-top:30px;
					margin-right:30px;

					@include sm {
						float:none;
						display:inline-block;
						margin-right:0;
						margin-top:20px;
					}

				}

			}

		}

	}

	/*
	Services *********************************
	Services *********************************
	Services *********************************
	Services *********************************
	*/
	.services {

		float:left;
		width:100%;
		margin-top:120px;

		@include sm {
			margin-top:40px;
			text-align:center;
		}

		h2 {
			float:left;
			margin-top:85px;
			color:#000;

			@include sm {
				float:none;
				margin-top:20px;
			}

		}

		.button {
			clear:both;
			float:left;
			margin-bottom:20px;

			@include sm {
				float:none;
				display:inline-block;
				margin-top:0px;
			}

		}

	}

	/*
	Over mij *********************************
	Over mij *********************************
	Over mij *********************************
	Over mij *********************************
	*/
	.about {

		float:left;
		width:100%;
		height:777px;
		position:relative;
		margin-top:40px;
		overflow:hidden;

		@include xl {
			height:957px;
		}

		@include sm {
			position:relative;
			overflow:inherit;
			text-align:center;
		}

		.right {
			position:absolute;
			right:0;
			top:80px;
			width:85%;
			padding:40px;
			background-color:#0F93CC;

			@include sm {
				width:100%;
				padding:20px;
				padding-top:40px;
				padding-bottom:40px;
				top:0;
			}

			.image {
				position:absolute;
				top:170px;
				left:-230px;
				width:550px;
				height:350px;
				background-image:url('../img/static/overmij.jpg');
				background-repeat:no-repeat;
				background-size:cover;

				@include sm {
					position:inherit;
					float:left;
					margin-top:20px;
					width:100%;
					height:300px;
				}

			}

			.inside {
				float:left;
				margin-left:40px;
				width:900px;

				@include xl {
					width:670px;
				}

				@include sm {
					width:100%;
					margin-left:0;
				}

			}

			.padding-left {
				margin-left:300px;

				@include sm {
					margin-left:0;
				}

				.button {
					margin-top:30px;
					margin-right:30px;

					@include sm {
						float:none;
						display:inline-block;
						margin-right:0;
						margin-top:20px;
					}

				}

			}

		}

	}

	/*
	Contact *********************************
	Contact *********************************
	Contact *********************************
	Contact *********************************
	*/
	.contact {

		float:left;
		width:100%;
		margin-top:120px;

		@include sm {
			margin-top:80px;
		}

		p {
			font-size:30px;
			line-height:60px;
			color:#000;

			@include sm {
				font-size:20px;
				line-height:40px;
			}

			a {
				color:#000;
				opacity:0.7;

				&:hover {
					opacity:1;
				}

			}

		}

		.socials {

			float:left;
			width:100%;
			margin-top:40px;
			text-align:center;
			margin-bottom:120px;

			a {
				display:inline-block;
				margin:10px;
				width:45px;
				height:45px;
				background-color:#e1e1e1;
				opacity:0.4;
				border-radius:50%;
				transition: all 1s;

				&:hover {
					transform:scale(1.10);
					background-color:#000;

					span {
						svg {
							fill:#fff;
						}
					}
				}

				span {
					float:left;

					&.icon-1 {
						margin-top:1px;
					}
					&.icon-2 {
						margin-top:2px;
					}
					&.icon-3 {
						margin-top:1px;
					}

					svg {
						margin-left:13px;
						margin-top:10px;
						width:20px;
						height:20px;

						fill {
							color:#888;
						}

					}

				}

			}

		}

	}

}