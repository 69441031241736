@import "_default.scss_include-mixins";	 

header {
	
	position:fixed;
	top:-100px;
	left:0;
	width:100%;
	padding-top:20px;
	padding-bottom:20px;
	background-color:#fff;
	box-shadow:0 0 30px rgba(0,0,0,0.02);
	z-index:200;

	@include sm {
		left:inherit;
		top:20px;
		right:20px;
		width:40px;
		height:40px;
		border-radius:50%;
		box-shadow:none;
	}

	.contact {

		position:fixed;
		top:15px;
		right:40px;
		z-index:100;

		@include sm {
			display:none;
		}

		a {
			float:right;
			margin-right:10px;
			width:45px;
			height:45px;
			background-color:#e1e1e1;
			opacity:0.4;
			border-radius:50%;
			transition: all 1s;

			&:hover {
				transform:scale(1.10);
				background-color:#000;

				span {
					svg {
						fill:#fff;
					}
				}
			}

			span {
				float:left;

				&.icon-1 {
					margin-top:1px;
				}
				&.icon-2 {
					margin-top:2px;
				}
				&.icon-3 {
					margin-top:1px;
				}

				svg {
					margin-left:13px;
					margin-top:10px;
					width:20px;
					height:20px;

					fill {
						color:#888;
					}

				}

			}

		}

	}

	.logo {
		
		float:left;
		text-decoration:none;

		@include sm {
			display:none;
		}

		h1 {
			font-family:'Gilroy',arial;
			font-size:30px;
			color:#f23700;
			margin:0;
			padding:0;
		}
	}

	nav {	

		float:left;
		margin-left:40px;

		@include lg {
			display:none;
		}


		a {
			float:left;
			margin-top:6px;
			margin-right:20px;
			font-family:'Gilroy',arial;
			font-weight:normal;
			font-size:23px;
			line-height:23px;
			color:#ccc;
			text-decoration: none;

			&:hover,
			&.active {
				color:#000;
			}

		}

	}

	.menu {

		display:none;

		@include sm {
			display:block;
			cursor:pointer;
		}

		position:relative;
		float:right;
		margin-right:10px;
		margin-top:10px;
		width:40px;
		height:27px;

		@include sm {
			margin:0;
		}

		.line {
			position:absolute;
			top:0;
			height:3px;
			width:100%;
			background-color:#000;

			@include sm {
				width:20px;
				margin-left:10px;
			}

			&.line-1 {
				top:0;

				@include sm {
					top:-7px;
				}
			}
			&.line-2 {
				top:10px;

				@include sm {
					top:-1px;
				}

			}
			&.line-3 {
				top:20px;

				@include sm {
					top:5px;
				}
			}

		}

	}

}