@import "_default.scss_include-mixins";

.mobile-menu {
	display:block;
	position:fixed;
	left:0;
	top:-9999px;
	width:100%;
	height:100%;
	background-color:rgba(255,255,255,1);
	z-index:9999;
	
	.scroller {
		position:relative;
		width:100vw;
		height:100vh;
		overflow:auto;

		.inside {
			padding:40px;
			text-align:center;

			.nav {

				float:left;
				width:100%;
				text-align:center;

				a {
					font-family:'Gilroy',arial;
					font-size:25px;
					line-height:45px;
					text-decoration:none;
					color:#777;

					&.active {
						color:#000;
					}
				}

			}

			.contact {
				float:left;
				width:100%;
				margin-top:20px;

				h2 {
					font-size:30px;
				}
			}

			.socials {

				float:left;
				width:100%;
				margin-top:40px;
				text-align:center;
				margin-bottom:120px;

				a {
					display:inline-block;
					margin:10px;
					width:45px;
					height:45px;
					background-color:#e1e1e1;
					opacity:0.4;
					border-radius:50%;
					transition: all 1s;

					&:hover {
						transform:scale(1.10);
						background-color:#000;

						span {
							svg {
								fill:#fff;
							}
						}
					}

					span {
						float:left;

						&.icon-1 {
							margin-top:1px;
						}
						&.icon-2 {
							margin-top:2px;
						}
						&.icon-3 {
							margin-top:1px;
						}

						svg {
							margin-left:13px;
							margin-top:10px;
							width:20px;
							height:20px;

							fill {
								color:#888;
							}

						}

					}

				}

			}

		}

	}


}